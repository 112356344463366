import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export const CommonConstant = {
    token: 'token',
    user: 'user',
    studentId: 'studentId',
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
    importStudentExcelFile: 'https://akshar-public-files.s3.ap-south-1.amazonaws.com/Import+Templates/Student+Import.xlsx',
};

export const Images = {
    user: 'assets/images/default_user.png',
    book: 'assets/images/add-book.png'
}

export const FeedbackCategory: string[] = ["Appreciation", "Complaint", "Other"];

export const OptionSubject: string[] = ["Y", "N"];

export const ChartColor = {
    domain: ["#66BD6D", "#FAA026", "#E96B56", "#55ACD2", "#B7332F", "#2C83C9", "#9166B8", "#92E7E8"],
};
export const AttendanceChartColor = {
    domain: ["#66BD6D", "#FAA026", "#FF0000"],
};

export const AssignmentReportColor = {
    domain: ["#ffc107", "#dc3545",'#007bff', "#28a745"],
};
