import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ValidateComponent} from "./validate/validate.component";
import {ModuleLoadGuard} from "../../../common/src/lib/guards";


const routes: Routes = [
    {
        path: 'validate',
        component: ValidateComponent
    },
    {
        path: '',
        canLoad: [ModuleLoadGuard],
        loadChildren: () => import('./screens/screens.module').then(m => m.ScreensModule)
    },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
