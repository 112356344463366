import { Component, OnInit } from '@angular/core';
import {AppService, CommonConstant, RoleService, UserAuthService} from 'projects/common/src/public-api';
import {ActivatedRoute, Router} from '@angular/router';
import { AppConfigService, BaseService, CommonAPIConstant } from 'projects/common/src/lib';
import { CommonUtility } from 'projects/common/src/lib';
import {NgxSpinnerService} from "ngx-spinner";
@Component({
    template: '',
})
export class ValidateComponent implements OnInit {

    constructor(private authService: UserAuthService,
        protected baseService: BaseService, private configService: AppConfigService,
        private router: Router,
        private appService: AppService,
        private roleService: RoleService,
                private activatedRoute: ActivatedRoute,
                private spinner: NgxSpinnerService,
    ) {
    }

    ngOnInit() {
        // window.sessionStorage.removeItem('appName');
        this.getToken();
        this.getColumnConfig();
        this.getSecurityGroups();
    }



    private getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const val = urlParams.get('userProfilePic');
        if (token) {
            window.sessionStorage.setItem('studentProfileId', urlParams.get('studentProfileId'));
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('SCHOOL_CODE', urlParams.get('code'));
            window.sessionStorage.setItem('orgCode', urlParams.get('code'));
           // window.sessionStorage.setItem('studentProfileId', urlParams.get('studentProfileId'));
            window.sessionStorage.setItem('studentName', urlParams.get('studentName'));
            window.sessionStorage.setItem('schoolName', urlParams.get('schoolName'));
            window.sessionStorage.setItem('appName', urlParams.get('appName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            window.sessionStorage.setItem('userProfilePic',  urlParams.get('userProfilePic'));


            setTimeout(() => {
                this.router.navigateByUrl('/', { preserveQueryParams: false });
            }, 900);
        }
    }

    private getColumnConfig() {
        this.appService.datatableSetup('StudentDashboard');
    }

    private getSecurityGroups() {
        this.roleService.getSecurityGroups('StudentDashboard');
    }

}
