



import { Injectable } from "@angular/core";
import { APIConstant } from "../constant";
import { EmployeeBasicInfo } from 'models';
import { Observable } from "rxjs";
import { BaseService, CommonUtility } from "projects/common/src/public-api";

@Injectable({
    providedIn: "root",
})
export class SearchService {

    constructor(protected baseService: BaseService) {
    }

    employee(seach: string): Observable<EmployeeBasicInfo[]> {
        const params = CommonUtility.convertObjectToParams({
            searchString: seach,
        });
        return this.baseService.get<EmployeeBasicInfo[]>(`${APIConstant.searchEmployee}?${params}`);
    }

}
